export function encodeBase64(value: string): string {
  if (!value) {
    return ''
  } else if (import.meta.client) {
    return window.btoa(value)
  } else {
    return Buffer.from(value, 'ascii').toString('base64')
  }
}

export function decodeBase64(value: string): string {
  if (!value) {
    return ''
  } else if (import.meta.client) {
    return window.atob(value)
  } else {
    return Buffer.from(value, 'base64').toString()
  }
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      encodeBase64,
      decodeBase64,
    },
  }
})
