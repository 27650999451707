type ExponeaAuthentication = {
  projectToken: string
  username: string
  password: string
  authKey?: string
}

export default defineNuxtPlugin((nuxtApp: any) => {
  const { locale, defaultLocale } = nuxtApp.$i18n
  const config = useRuntimeConfig()
  let auth = <ExponeaAuthentication>{}

  const exponea: { [key: string]: ExponeaAuthentication } = config.public.exponea
  if (exponea[locale.value]) {
    auth = exponea[locale.value]
  } else {
    auth = exponea[defaultLocale]
  }

  // Generate authKey using "username:password" as its input and base64 encode it:
  auth.authKey = nuxtApp.$encodeBase64(`${auth.username}:${auth.password}`)

  return {
    provide: {
      exponeaAuthentication: auth,
    },
  }
})
