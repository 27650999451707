export type AppLogLevels = 'error' | 'warn' | 'info' | 'debug' | 'trace'
type ConsolaLevels = 0 | 1 | 2 | 3 | 4 | 5

type ConsolaToWinstonLevelMapper = Record<ConsolaLevels, AppLogLevels>

export const levels: ConsolaToWinstonLevelMapper = {
  0: 'error',
  1: 'warn',
  2: 'info',
  3: 'info',
  4: 'debug',
  5: 'trace',
}

export const toConsolaLevelMapper = (wLevel: AppLogLevels): ConsolaLevels => {
  const consolaLevel = Object.entries(levels)
    .sort((a, b) => Number(b[0]) - Number(a[0]))
    .find((e) => wLevel == e[1])?.[0]
  return Number(consolaLevel) as ConsolaLevels
}
