/**
 * Gets the facets (pretty urls) from Bloomreach CMS these should match exactly (case sensitive) with the facets from Bloomreach Discovery.
 * @returns {Promise<PrettyUrlFilter[]>} The facets matched with the pretty URL filters.
 * @throws {Error} If failed to fetch the pretty URL facets.
 */
import useCache from '~/composables/useCache'

export default async () => {
  const logger = useAppLogger('loadPrettyUrlFilters')
  const { get: getCache, set: setCache } = useCache('facets')
  const { $brxmEndpointResolver, $i18n } = useNuxtApp()
  const { locale } = $i18n
  const { prettyUrls } = storeToRefs(usePrettyUrlStore())

  const facets = getCache(`facets_${locale.value}`)

  if (facets) {
    prettyUrls.value = facets
    return facets
  }

  try {
    const data = await $fetch($brxmEndpointResolver.resolveDocumentsEndPoint('/facets/facets'))

    // Get the facet $refs from 'prettyUrlItems'
    const prettyUrlRefs =
      data?.content[data?.document?.$ref.substring(9)].data?.prettyUrlItems?.map((item) =>
        item.$ref.split('/').pop(),
      ) || []

    // Get the facets object by reference and map to useful values
    const facets = prettyUrlRefs.map((ref) => {
      const facet = data?.content?.[ref]?.data
      // Facet type and value are in one string: "key=value" (e.g. "colors=black")
      const facetTypeAndValue = facet.facetValue?.split('=')

      return <PrettyUrlFilter>{
        label: facetTypeAndValue?.[1] || facet.facetValue,
        slug: facet.prettyUrl,
        type: facetTypeAndValue?.[0] || 'default',
      }
    })

    if (facets?.length < 1) {
      throw new Error('[loadPrettyUrlFilters] No facets found')
    }

    logger.info(`[loadPrettyUrlFilters] Got ${facets.length} facets for ${locale.value}`)

    setCache(`facets_${locale.value}`, facets)

    prettyUrls.value = facets
  } catch (err) {
    throw new Error('Failed to fetch pretty url facets')
  }
}
