import _debounce from '~/utils/debounce'

interface CacheItemData {
  customer_ids: string
  attributes: Array<any>
}

interface CacheItem {
  url: string
  data: CacheItemData
  type: string
  attributesLength: number
}

let cache: CacheItem[] = []
let cacheLoading = true
let cacheResult

const debounceAndConsolidate = _debounce(async () => {
  // Condsolidate attributes
  let attributes = []
  for (let item of cache) {
    attributes = [...attributes, ...item.data?.attributes]
  }

  const consolidated = cache[0]

  // Check if object exists
  if (!consolidated) {
    cache = []
    return
  }

  consolidated.data.attributes = attributes

  // Make the call
  cacheResult = await useExponeaApi().postConsolidated(consolidated.url, consolidated.data, consolidated.type)
  cacheLoading = false

  // Reset caching variables
  cache = []
}, 500)

const cacheHandler = async (url, data, type) => {
  // Push call to cache
  cacheLoading = true
  const cachePositionStart = cache.length
    ? cache.map((item) => item.attributesLength).reduce((curr, prev) => curr + prev)
    : 0
  const cachePositionEnd = cachePositionStart + data.attributes.length
  cache.push({ url, data, type, attributesLength: data.attributes.length })

  // Consolidate all requested items and make the call
  debounceAndConsolidate()

  // Wait until call is made
  while (cacheLoading) {
    await new Promise((resolve) => requestAnimationFrame(resolve))
  }

  // Get the requested data back and return it
  return cacheResult.slice(cachePositionStart, cachePositionEnd)
}

export const useExponeaApi = () => {
  const { $exponeaAuthentication, $config } = useNuxtApp()
  const baseUrl = (type: string) =>
    `${$config.public.storeUrl}/api/exponea/${type}/v2/projects/${$exponeaAuthentication.projectToken}`

  const get = async (url: string, type = 'data') => {
    try {
      const response = await $fetch(baseUrl(type) + url, {
        method: 'GET',
        headers: authHeader(),
      })
      return response
    } catch (error) {
      throw error
    }
  }

  const post = async (url: string, data, type = 'data') => {
    if (url === '/customers/attributes' && 'cookie' in data.customer_ids) {
      // This url tends to be called more often at once. So consolidate it.
      return await cacheHandler(url, data, type)
    } else {
      return postConsolidated(url, data, type)
    }
  }

  const postConsolidated = async (url: string, data, type = 'data') => {
    try {
      const response = await $fetch(baseUrl(type) + url, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
      })
      return response?.results
    } catch (error) {
      throw error
    }
  }

  const authHeader = (): any => {
    return {
      'content-type': 'application/json',
      Authorization: `Basic ${$exponeaAuthentication.authKey}`,
    }
  }

  return {
    get,
    post,
    postConsolidated,
  }
}

export default useExponeaApi
