import { defineStore } from 'pinia'
import { getCategoryListCommand } from './commands/getCategoryListCommand'
import type { CategoryList, ComposableFunctionArgs } from './categoryStore'

export const useCategoryStore = defineStore('category', () => {
  const logger = useAppLogger("useCategoryStore")
  const state = reactive({
    loading: true,
    error: { load: null },
    categoriesList: [],
  })

  const getCategoryList = async (params: ComposableFunctionArgs<{ [key: string]: any }>): Promise<CategoryList> => {
    logger.debug('categoryStore/load', { params })
    state.categoriesList = []

    try {
      if (!tryUseNuxtApp()) {
        throw new Error('categoryStore/load: Nuxt app not found')
      }

      state.loading = true
      state.categoriesList = await getCategoryListCommand.execute(params)

      logger.debug('[Result]:', { categoriesList: state.categoriesList })

      state.error.load = null

      return state.categoriesList
    } catch (err) {
      state.error.load = err
      logger.error('categoryStore/load', err)
    } finally {
      state.loading = false
    }
  }

  return {
    ...toRefs(state),
    getCategoryList,
  }
})

export default useCategoryStore
