import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  async scrollBehavior(to, from, savedPosition) {
    const { loaded: pageLoaded } = storeToRefs(usePageStore())

    const isCatalogOrSearchPage = to.path.includes('.html') || to.path.includes('/catalogsearch/result')

    if (isCatalogOrSearchPage && to.fullPath !== from.fullPath) {
      pageLoaded.value = false

      // Wait until page is fully loaded before scrolling
      await new Promise<void>((resolve) => {
        let timePassed = 0
        const start = performance.now()

        const checkPageLoaded = () => {
          timePassed = performance.now() - start
          if (pageLoaded.value || timePassed > 2500) {
            resolve()
          } else {
            requestAnimationFrame(checkPageLoaded)
          }
        }

        requestAnimationFrame(checkPageLoaded)
      })
    }

    // If going from a PDP back to a PCP or Search that has paginated we don't have the same amount of
    // products as we had before. So we have to find the product "manually" and scroll to it
    if (isCatalogOrSearchPage && !!to.query.p) {
      const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions'))
      const scrollPosition = scrollPositions[to.fullPath]

      const element = document.querySelector(`.product-card[data-sku=${scrollPosition?.sku?.replace(' ', '')}]`)
      if (element) {
        return {
          behavior: 'instant',
          top:
            element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - scrollPosition.offsetTop,
        }
      }
    }

    // If no pagination, default scroll
    if (!to.query.p) {
      return {
        top: savedPosition?.top || 0,
        behavior: 'instant',
      }
    }

    // Else = load more, do nothing
    return false
  },
}
