/**
 * Returns the filters from the 'pretty url' route path.
 */

import { facetIds } from '../useBloomreachDiscoveryStore'

export default () => {
  const logger = useAppLogger('getFiltersFromRoutePath')
  const bloomreachDiscoveryStore = useBloomreachDiscoveryStore()
  const { selectedPrettyUrls, selectedFilters, appliedFilters, selectedSortOption } =
    storeToRefs(bloomreachDiscoveryStore)
  const { prettyUrls } = storeToRefs(usePrettyUrlStore())
  const { routeData } = usePageStore()
  const route = useRoute()
  delete routeData?.query?.q // We don't need this in the filters
  selectedPrettyUrls.value = routeData?.facets || []

  let pathFilters = {}

  if (routeData?.has_pretty_filters) {
    pathFilters = routeData?.facets?.reduce((prev, curr) => {
      const facet = prettyUrls.value?.find((f) => {
        return (
          encodeURI(f.slug?.toLowerCase()) === encodeURI(curr.toLowerCase()) ||
          encodeURI(f.slug?.toLowerCase()) === curr.toLowerCase() ||
          f.slug?.toLowerCase() === decodeURIComponent(curr.toLowerCase())
        )
      })

      const { label, type } = facet ?? {}
      if (!label || !type) {
        // invalid pretty filter therefore page doesn't exist
        if (!routeData.relative_url.includes(curr)) {
          logger.warn(`Ignoring invalid pretty filter in URL (${curr}).`)
        }
        return prev
      }

      let mappedType = bloomreachDiscoveryStore.facetsKeysMap[type] ?? type

      return {
        ...prev,
        [mappedType]: Array.isArray(prev[mappedType]) ? [...prev[mappedType], label] : [label],
      }
    }, {})
  }

  let queryFilters: { [k: string]: any } = []

  const query = import.meta.server ? route.query : Object.fromEntries(new URLSearchParams(window.location.search))

  if (query && Object.keys(query).length > 0) {
    const queryParams = { ...query, q: undefined }
    queryFilters = Object.fromEntries(
      Object.entries(queryParams)
        .filter((query) => query[0] !== 'p' && facetIds.includes(query[0]))
        .map(([key, value]) => {
          let newVal: any = Array.isArray(value?.toString().split(',')) && key ? value.toString().split(',') : [value]
          if (key === 'price') newVal = value
          return [key, newVal]
        }),
    )
  }

  const result = { ...pathFilters, ...queryFilters }
  const currentSort = query?.sort

  selectedFilters.value = result
  appliedFilters.value = result
  selectedSortOption.value = Array.isArray(currentSort) ? currentSort[0] : currentSort || ''
}
