/* eslint-disable  */

export default {
  'Page {currentPage} of {totalPages}': 'Seite {currentPage} von {totalPages}',
  'A 10% discount on your next purchase?': '10 € + 5 € Rabatt bei deiner nächsten Bestellung sparen?',
  'A customer with the same email address already exists in an associated website.':
    'Ein Konto mit derselben E-Mail-Adresse existiert bereits auf einer verknüpften Website.',
  'About us': 'Über uns',
  Account: 'Konto',
  'Account dashboard': 'Kontoübersicht',
  'Account Details': 'Kontoinformation',
  'Add new address': 'Neue Adresse hinzufügen',
  'Add review': 'Bewertung schreiben',
  'Add the address': 'Füge deine Adresse hinzu',
  'Add to cart': 'In den Warenkorb legen',
  'Quick add to cart': 'Sofort shoppen',
  Adding: 'Wird hinzugefügt',
  'Added!': 'Hinzugefügt!',
  'Add to compare': 'Zum Vergleichen hinzufügen',
  'Add to Wishlist': 'Zur Wunschliste hinzufügen',
  'Added to cart': 'Zum Warenkorb hinzugefügt',
  'Additional Information': 'Zusätzliche Information',
  'Addresses details': 'Adressen-Details',
  'Adjusted opening hours': 'Öffnungszeiten an Feiertagen',
  'Allow order notifications': 'Bestellbenachrichtigungen erlauben',
  and: 'und',
  Apply: 'Übernehmen',
  'Attention!': 'Achtung!',
  'Available again soon': 'Bald wieder verfügbar',
  'Available from date': 'Verfügbar ab dem {date}',
  'BACK SOON': 'BALD ZURÜCK',
  'Back to home': 'Zurück zur Startseite',
  'Back to homepage': 'Zurück zur Homepage',
  'Back to top': 'Zurück nach oben',
  'Be aware of upcoming sales and events. Receive gifts and special offers!':
    'Informiere dich über bevorstehende Sale-Aktionen und Aktionen. Erhalte Geschenke und Rabatte!',
  'Your feedback means a lot to us: write a review!': 'Dein Feedback ist wertvoll: Schreibe eine Bewertung!',
  Billing: 'Abrechnung',
  'Billing address': 'Rechnungsadresse',
  'Birthday incorrect': 'Geburtstag falsch',
  Brand: 'Marke',
  'By: {author}': 'Von: {author}',
  Campaign: 'Kampagne',
  Cancel: 'Abbrechen',
  Canceled: 'Storniert',
  Cart: 'Warenkorb',
  Categories: 'Kategorien',
  Change: 'Änderung',
  'Change password': 'Passwort ändern',
  'Change password your account':
    'Wenn du das Passwort ändern möchtest, um auf dein Konto zuzugreifen, gib die folgenden Informationen ein',
  'Change to grid view': 'Zur Rasteransicht wechseln',
  'Change to list view': 'Zur Listenansicht wechseln',
  'Choose an option': 'Wähle eine Option',
  'Choose your language': 'Wähle deine Sprache',
  City: 'Stadt',
  'Clear all': 'Alles löschen',
  Close: 'Schließen',
  'Close search': 'Suchen schließen',
  closed: 'geschlossen',
  'Cocktail & Party': 'Cocktail & Party',
  Color: 'Farbe',
  'Colorful summer dresses are already in store': 'Bunte Sommerkleider liegen bereits im Store bereit',
  Colors: 'Farben',
  'COMING SOON': 'BALD ERHÄLTILICH',
  'Coming soon form description': 'Sobald der Artikel vorrätig ist, erhältst du eine E-Mail von uns.',
  'Coming soon form title': 'Erhalte eine E-Mail, wenn dieser Artikel veröffentlicht wird!',
  'Commercial information':
    'und erklärst dich damit einverstanden, personalisierte Handelsinformationen von My Jewellery per E-Mail zu erhalten.',
  'Contact Details': 'Kontaktinformationen',
  'Contact details updated': 'Halte deine Adresse und Kontaktdaten auf dem neuesten Stand.',
  'Contact us': 'Kontaktiere uns',
  'Continue shopping': 'Weitershoppen',
  'Continue to billing': 'Weiter zur Rechnung',
  'Continue to payment': 'Weiter zur Zahlung',
  'Continue to shipping': 'Weiter zum Versand',
  'Cookies Link': '/datenschutz-cookie-einstellungen',
  'Cookies Policy': 'Cookie-Richtlinie',
  Country: 'Land',
  'Create an account': 'Konto erstellen',
  'Current Password': 'Aktuelles Passwort',
  'Customer Reviews': 'Bewertungen',
  'Customer service': 'Kundenservice',
  Date: 'Datum',
  'Date of birth': 'Geburtsdatum',
  Day: 'Tag',
  Default: 'Standard',
  'Default Billing Address': 'Standard-Rechnungsadresse',
  'Default Shipping Address': 'Standard-Lieferadresse',
  Delete: 'Löschen',
  'Delivery & Payment': 'Lieferung & Zahlung',
  'Delivery & Returns': 'Versand & Rücksendung',
  Departments: 'Abteilungen',
  Description: 'Beschreibung',
  'Details and status orders':
    'Überprüfe die Details und den Status deiner Bestellungen im Onlineshop. Du kannst deine Bestellung auch stornieren oder eine Rücksendung anfordern.',
  Discount: 'Rabatt',
  'Discover our new items!': 'Entdecke unsere neuen Artikel!',
  Done: 'Fertig',
  'Done. You’ve subscribed': 'Geschafft! Du hast dich angemeldet',
  Download: 'Herunterladen',
  'Download all': 'Alle herunterladen',
  'Download our application to your mobile': 'Lade unsere App auf deinem Smartphone herunter',
  Dresses: 'Kleider',
  'Ear piercing in': 'Ohrlöcher stechen in',
  'Eco Sandals': 'Öko-Sandalen',
  Edit: 'Bearbeiten',
  'Email address': 'E-Mail-Adresse',
  'Email you are using to sign in': 'E-Mail-Adresse, die du für die Anmeldung nutzt',
  'Empty bag': 'Leere Tasche',
  'Enjoy your free account': 'Profitiere mit einem kostenfreien Account!',
  'Enter promo code': 'Gutscheincode eingeben',
  'Enter your email address': 'E-Mail Adresse',
  'Existing customers': 'Registrierte Kund/-innen',
  'Extended overview': 'Erweiterte Übersicht',
  'Fashion to take away': 'Mode zum Mitnehmen',
  Features: 'Produkt Information',
  Feedback: 'Dein Feedback ist uns wichtig. Lass uns wissen, was wir verbessern können.',
  'Feel free to edit':
    'Du kannst die folgenden Daten jederzeit bearbeiten, damit dein Konto immer auf dem neuesten Stand ist.',
  Filters: 'Filter',
  'Filters & sorting': 'Filter & Sortierung',
  'Find out more': 'Entdecke mehr',
  'Find stunning women’s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.':
    'Entdecke atemberaubende Cocktailkleider und Partykleider für Damen und falle auf in Spitzen- ode Metallic-Kleidern.',
  'First Name': 'Vorname',
  Fit: 'Passform',
  'Forgot Password': 'Wenn du dein Passwort vergessen hast, kannst du es zurücksetzen.',
  'Forgot Password Modal Email': 'E-Mail-Adresse, mit der du dich anmeldest:',
  'Forgot Your Password?': 'Passwort vergessen?',
  forgotPasswordConfirmation:
    'Vielen Dank! Wenn ein Konto mit der E-Mail-Adresse {0} verknüpft ist, findest du in deinem Posteingang eine Nachricht mit einem Link zum Zurücksetzen des Passworts.',
  'Forgotten password?': 'Passwort vergessen?',
  'Free shipping': 'Kostenloser Versand',
  'Get inspired': 'Lass dich inspirieren',
  'Gift? Add a gift wrap!': 'Geschenk? Füge die passende Geschenkverpackung hinzu!',
  'Go back': 'Zurück',
  'Go back shopping': 'Weitershoppen',
  'Go back to shop': 'Zurück zum Shop',
  'Go to checkout': 'Zur Kasse gehen',
  'Go To Product': 'Zum Produkt',
  'Grand Total': 'Gesamtsumme',
  Guarantee: 'Garantie',
  Help: 'Hilfe',
  'Help & FAQs': 'Hilfe & FAQs',
  hide: 'ausblenden',
  'Highest price': 'Höchster Preis',
  Home: 'Zuhause',
  'House/Apartment number': 'Haus-/Wohnungsnummer',
  'I agree to': 'Ich stimme zu',
  'I confirm subscription': 'Ich bestätige das Abonnement',
  'I have read and understand the': '',
  'I want to create an account': 'Ich möchte ein Konto erstellen',
  'In stock': 'Auf Lager',
  'Info after order':
    'Du kannst dich mit deiner E-Mail-Adresse und Passwort einloggen. In deinem Account kannst du deine Bestellungen, Profildaten und dein Abonnement für Newsletter bearbeiten.',
  Instruction1: 'Kümmere dich um mich',
  Instruction2: 'Nur hier für die Pflegehinweise?',
  Instruction3: 'Ja, das haben wir uns gedacht',
  'Invalid email': 'Ungültige E-Mail-Adresse',
  'Invalid Password Message':
    'Das Passwort muss mindestens 8 Zeichen lang sein und muss mindestens enthalten: Ein Groß- und Kleinbuchstabe, eine Ziffer oder ein Sonderzeichen (z. B. , . _ & ? usw.)',
  'It was not possible to request a new password, please check the entered email address.':
    'Es war nicht möglich, ein neues Passwort anzufordern. Bitte überprüfe die eingegebene E-Mail-Adresse.',
  Item: 'Artikel',
  Items: 'Artikel',
  'Keep me updated': 'Halte mich auf dem Laufenden',
  Kidswear: 'Kinderkleidung',
  'Last Name': 'Nachname',
  'Learn more': 'Mehr erfahren',
  'Let’s create an account. It has many advantages, such as order tracking, fast checkout and registration of multiple addresses.':
    'Lass uns einen Account erstellen! Denn das hat viele Vorteile: Du kannst zum Beispiel deine Bestellung schneller aufgeben, bearbeiten und verfolgen oder mehrere Lieferadressen registrieren.',
  'Let’s start now – we’ll help you': 'Fangen wir an - wir helfen dir gerne!',
  'Linen Dresses': 'Leinenkleider',
  'Load more reviews': 'Weitere Bewertungen anzeigen',
  'Log in to save your favourites!': 'Melde dich an, um deine Favoriten zu speichern!',
  'Log into your account': 'In deinem Konto einloggen',
  'Log out': 'Abmelden',
  Login: 'Anmeldung',
  'login in to your account': 'In deinem Konto einloggen',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    'Es sieht so aus, als hättest du noch keine Artikel zu deinem Warenkorb hinzugefügt. Füge jetzt deine Lieblingsartikel hinzu!',
  'Lowest price': 'Niedrigster Preis',
  'Make an order': 'Bestellung aufgeben',
  'Manage Addresses': 'Adressen verwalten',
  'Manage addresses':
    'Verwalte deine gewünschten Adressen (Arbeitsadresse, Privatadresse...). So musst du sie nicht bei jeder Bestellung manuell eingeben.',
  'Manage billing addresses':
    'Verwalte deine gewünschten Rechnungsadressen (Arbeitsadresse, Privatadresse ...). So musst du sie nicht bei jeder Bestellung manuell eingeben.',
  'Manage shipping addresses':
    'Verwalte deine gewünschten Versandadressen (Arbeitsadresse, Privatadresse ...). So musst du sie nicht bei jeder Bestellung manuell eingeben.',
  'Match it with': 'Style dies mit',
  Material: 'Material',
  'Men fashion': 'Herrenmode',
  Menu: 'Menü',
  Month: 'Monat',
  'Month flower': 'Monat Blume',
  more: 'mehr',
  'My Account': 'Mein Konto',
  'My billing and shipping address are the same': 'Meine Rechnungs- und Lieferadresse sind identisch',
  'My Cart': 'Mein Warenkorb',
  'My newsletter': 'Mein Newsletter',
  'My orders': 'Meine Bestellungen',
  'My profile': 'Kontoinformation',
  'My reviews': 'Meine Bewertungen',
  'My wishlist': 'Mein Wunschzettel',
  Name: 'Name',
  'Name A-Z': 'Name A-Z',
  'New customer': 'Neue/-r Kund/-in',
  'New Password': 'Neues Passwort',
  'New Products': 'Neue Produkte',
  Newest: 'Aktuell',
  'No account': 'Du hast noch keinen Account?',
  'No products for query "{keyword}"': 'Keine Produkte für die Suchanfrage "{keyword}"',
  'No products were found for the search query':
    'Einen Moment bitte, wir suchen aktuell die besten Suchergebnisse für dich heraus. Drücke Enter oder klicke auf die Lupe, um alle Suchergebnisse für "<strong>{query}</strong>" anzuzeigen.',
  'No results for “{keyword}”': 'Keine Suchergebnisse für “{keyword}”',
  'Notify me on release': 'Benachrichtige mich',
  'Open search': 'Suchen öffnen',
  'Opening hours': 'Öffnungszeiten',
  option: 'Option | Optionen',
  or: 'oder',
  'or fill the details below': 'oder füllen die Details unten aus',
  'Order {price} more for free shipping': 'Dir fehlen noch {price} bis zum kostenlosen Versand',
  'Order details': 'Bestelldetails',
  'Order history': 'Meine Bestellungen',
  'Order information': 'Bestellinformationen',
  'Order No.': 'Bestellnummer',
  'Order summary': 'Bestellübersicht',
  'Original price': 'Preis',
  'Other products you might like': 'Andere Produkte, die dir gefallen könnten',
  'Out of stock': 'Ausverkauft',
  Overview: 'Übersicht',
  Password: 'Passwort',
  'Password change': 'Passwortänderung',
  'Password Changed': 'Passwort erfolgreich geändert. Du kannst nun zur Startseite zurückkehren und dich anmelden.',
  'Passwords don’t match': 'Passwörter stimmen nicht überein',
  'Pay for order': 'Bestellung bezahlen',
  Payment: 'Zahlung',
  'Payment & Delivery': 'Zahlung & Lieferung',
  'Payment & shipping methods': 'Zahlung & Versandmethoden',
  'Payment method': 'Zahlungsmethode',
  'Payment methods': 'Zahlungsmethoden',
  'Personal data': 'Persönliche Daten',
  'Personal details': 'Persönliche Daten',
  'Personal Details': 'Persönliche Daten',
  'Personal information': 'Persönliche Daten',
  'Phone number': 'Telefonnummer',
  'Phone model': 'Smartphone Modell',
  'Please enter your email address below to receive a password reset link.':
    'Bitte gib deine E-Mail-Adresse ein, um einen Link zu erhalten, mit dem du dein Passwort zurücksetzen kannst.',
  'Please select a country first': 'Bitte wähle zuerst ein Land aus',
  'Please select the {names} below': 'Bitte wähle {names}',
  'Please select a color first': 'Bitte wähle zuerst eine Farbe aus',
  'Please select the option above first': 'Wähle bitte zuerst die Option hier oben aus',
  'Please type your current password to change your email address.':
    'Bitte gib dein aktuelles Passwort ein, um deine E-Mail-Adresse zu ändern.',
  Price: 'Preis',
  Privacy: 'Datenschutz',
  'Privacy Link': '/datenschutz-cookie-einstellungen',
  'Privacy Policy': 'Datenschutzrichtlinie',
  Product: 'Produkt',
  product: 'Produkt | Produkte',
  'Product recommendations unknown user': 'Entdecke unsere Besteller',
  'Product recommendations with name': 'Diese Artikel könnten dir auch gefallen, {firstName}',
  'Product recommendations with name - male': 'Entdecke mehr Geschenkinspirationen, {firstName}',
  'Product recommendations without name': 'Diese Artikel könnten dir auch gefallen',
  'Product recommendations without name - male': 'Entdecke mehr Geschenkinspirationen',
  'Product suggestions': 'Produktvorschläge',
  Products: 'Produkte',
  'Products found': 'Produkte gefunden',
  'Entre ton code de réduction': 'Gutscheincode',
  'Purchase terms': 'Kaufbedingungen',
  'Quality in the details': 'Produkteigenschaften',
  Quantity: 'Menge',
  Rating: 'Bewertung',
  'Read all reviews': 'Alle Bewertungen lesen',
  'Read and understand': 'Ich habe die Bedingungen gelesen und akzeptiere diese.',
  'Read less': 'Weniger lesen',
  'Read more': 'Weiterlesen',
  'Read reviews': 'Bewertungen lesen',
  'Reading time: {readingTime} minutes': 'Lesezeit: {readingTime} Minuten',
  Recommended: 'Empfohlen',
  Register: 'Registrieren',
  'Register today': 'Melde dich noch heute an',
  'Remove Address': 'Adresse entfernen',
  'Remove from Wishlist': 'Von Wunschliste entfernen',
  'Repeat Password': 'Wiederhole das Passwort',
  'Reset Password': 'Passwort zurücksetzen',
  'Return home': 'Zurück zur Homepage',
  Returns: 'Rücksendungen',
  Review: 'Bewertung',
  'Review my order': 'Meine Bestellung überprüfen',
  Reviews: 'Bewertungen',
  SALE: 'SALE',
  'Same as shipping address': 'Wie Versandadresse',
  'Save changes': 'Änderungen speichern',
  'Save for later': 'Für später speichern',
  'Save Password': 'Passwort speichern',
  Search: 'Suche',
  'Search for items': 'Nach Artikeln suchen',
  'Search for products, ...': 'Suche nach Produkte, ...',
  'Search for products, categories, ...': 'Suche nach Produkte, Kategorien, ...',
  'Search results': 'Suchergebnisse',
  'Search results for: “{keyword}”': 'Suchergebnisse für: “{keyword}”',
  Season: 'Jahreszeit',
  'Sections that interest you': 'Rubriken, die dich interessieren',
  'See all': 'Alle anzeigen',
  'See all products': 'Alle Produkte ansehen',
  'See all results': 'Alle Ergebnisse anzeigen',
  'See our new items link': '/de/neuheiten.html',
  'See our new items!': 'Unsere neuen Artikel',
  'Select payment method': 'Zahlungsmethode auswählen',
  'Select shipping method': 'Versandart auswählen',
  'Send my feedback': 'Sende mein Feedback',
  'Set as default billing': 'Als Standardabrechnung festlegen',
  'Set as default shipping': 'Als Standardversand festlegen',
  'Set up newsletter':
    'Richte deinen Newsletter ein und wir senden dir wöchentlich Informationen zu neuen Produkten und Trends aus den von dir ausgewählten Bereichen.',
  'Share your experience and help others make the right decision':
    'Teile deine Erfahrungen und hilf anderen, die richtige Wahl zu treffen.',
  'Share your look': 'Teile deinen Look',
  'Share Your Look': 'Teilen deinen Look',
  Shipping: 'Versand',
  'Shipping address': 'Lieferanschrift',
  'Shipping details': 'Versanddetails',
  'Shipping method': 'Versandart',
  'Shop now': 'Jetzt einkaufen',
  'Show all': 'Alles anzeigen',
  'Show all results': 'Alle Ergebnisse anzeigen',
  'Show less shop-in-shops': 'Zeige weniger Partnergeschäfte',
  'show more': 'Mehr anzeigen',
  'Show more products': 'Mehr Produkte zeigen',
  'Show more shop-in-shops': 'Mehr Partnergeschäfte anzeigen',
  'Show on page': 'Auf Seite anzeigen',
  'Show previous products': 'Vorherige Produkte anzeigen',
  'Sign in': 'Anmelden',
  'sign me up': 'Melde mich an',
  'Sign Up for Newsletter': 'Ja, ich möchte 10% Rabatt, die neuesten Artikel und exklusive Rabatte in meinem Postfach!',
  'Sign-in information': 'Anmeldeinformationen',
  Size: 'Größe',
  'Size chart': 'Größendiagramm',
  'Size guide': 'Größentabelle',
  'Sku color': 'Farbe',
  'Sold out form description':
    'Gib hier unten deine E-Mail Adresse ein, damit wir dich benachrichtigen können, sobald die gewünschte Größe oder Farbe wieder vorrätig ist!',
  'Sold out form title': 'Sobald der Artikel vorrätig ist, erhältst du eine E-Mail von uns!',
  'Sorry, the page you are looking for does not seem to exist anymore.':
    'Sorry, die Seite, die du suchst, scheint nicht mehr zu existieren.',
  'Sort by': 'Sortieren nach',
  'Sort: Default': 'Sortierung: Standard',
  'Sort: Name A-Z': 'Sortierung: Name A-Z',
  'Sort: Name Z-A': 'Sortierung: Name Z-A',
  'Sort: Price from high to low': 'Preis von hoch bis niedrig',
  'Sort: Price from low to high': 'Preis von niedrig bis hoch',
  'Start shopping': 'Loslegen',
  'State/Province': 'Staat/Bundesland',
  'Street Name': 'Straßenname',
  'Style profile': 'Stil',
  Subscribe: 'Abonnieren',
  'Subscribe to newsletter': 'Ich melde mich für den Newsletter an',
  'Subscribe to Newsletters': 'Newsletter abonnieren',
  'Subscribe your friend!': 'Melde deine Freundin oder deinen Freund an!',
  subscribeToNewsletterModalContent:
    'Wenn du dich für den Newsletter angemeldet haat, erhältst du spezielle Angebote und Nachrichten von My Jewellery per E-Mail. Wir werden deine E-Mail-Adresse zu keinem Zeitpunkt an Dritte verkaufen oder weitergeben. Bitte beachte unsere {0}.’,',
  Subtotal: 'Zwischensumme',
  'Subtotal price': 'Zwischensumme Preis',
  'Successful placed order':
    'Du hast die Bestellung erfolgreich aufgegeben. Du kannst den Status deiner Bestellung über unsere Lieferstatusfunktion einsehen. Du erhältst außerdem eine Bestätigungs-E-Mail mit Details deiner Bestellung und einem Link zur Sendungsverfolgung.',
  Summary: 'Zusammenfassung',
  'SUMMER COLLECTION {year}': 'SOMMERKOLLEKTION {year}',
  'Summer Sandals': 'Sommersandalen',
  'T-Shirts': 'T-Shirts',
  Tax: 'Steuer',
  'Terms and conditions': 'Allgemeine Geschäftsbedingungen',
  'Thank You Inbox':
    'Wenn die Nachricht nicht in deinem Posteingang ankommt, versuche es mit einer anderen E-Mail-Adresse, mit der du dich möglicherweise registriert hast.',
  'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.':
    'Die Kontoanmeldung war falsch oder dein Konto wurde vorübergehend deaktiviert. Bitte versuche es später erneut.',
  'The email field must be a valid email': 'Das E-Mail-Feld muss eine gültige E-Mail-Adresse beinhalten.',
  'The field should have at least 2 characters': 'Das Feld sollte mindestens 2 Zeichen lang sein',
  'The field should have at least 4 characters': 'Das Feld sollte mindestens 4 Zeichen lang sein',
  'The field should have at least 8 characters': 'Das Feld sollte mindestens 8 Zeichen lang sein',
  'The office life': 'Das Büroleben',
  'The Office Life': 'Das Büroleben',
  'The user account data was successfully updated!': 'Die Account-Daten wurden erfolgreich aktualisiert!',
  'There are no shipping methods available for this country. We are sorry, please try with different country.':
    'Für dieses Land sind keine Versandarten verfügbar. Es tut uns leid, bitte versuche es mit einem anderen Land oder später erneut.',
  'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.':
    'Beim Versuch, Versandarten abzurufen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuche es mit anderen Versanddetails oder später erneut.',
  'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.':
    'Beim Versuch, diese Versandart auszuwählen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuche es mit einer anderen Versandart.',
  'These are our bestsellers': 'Das sind unsere Bestseller',
  'These are your last viewed items': 'Dies sind deine zuletzt angesehenen Artikel',
  'This field is required': 'Dieses Feld ist erforderlich',
  'This is a required field.': 'Vervollständige bitte die notwendigen Angaben.',
  Total: 'Gesamt',
  'Total items': 'Gesamtanzahl',
  'Total price': 'Gesamtpreis',
  'Update password': 'Passwort aktualisieren',
  'Update personal data': 'Persönliche Daten aktualisieren',
  'Update the address': 'Adresse aktualisieren',
  'Use your personal data':
    'Bei My Jewellery legen wir großen Wert auf Datenschutz und verpflichten uns, deine persönlichen Daten zu schützen. Erfahre mehr darüber, wie wir deine persönlichen Daten verwalten und verwenden.',
  'User Account': 'Benutzerkonto',
  View: 'Ansicht',
  'View all items on your wishlist': 'Alle Artikel auf deiner Wunschliste anzeigen',
  'View details': 'Details anzeigen',
  'View our newest items & items that have been saved especially for you below! Or shop from one of our categories.':
    'Schau dir unsere neuesten Artikel an, die wir speziell für dich unten gespeichert haben! Oder shoppe aus einer unserer Kategorien.',
  'Vue Storefront Next': 'Vue Storefront Next',
  'We believe you might like these items': 'Wir glauben, dass dir diese Artikel gefallen könnten',
  'We haven’t found any results for given phrase': 'Wir haben keine Ergebnisse für deine Suchanfrage gefunden',
  'We think you’ll like these items': 'Diese Artikel könnten dir auch gefallen',
  'Who we are': 'Wer wir sind',
  Wishlist: 'Wunschliste',
  'Women fashion': 'Damenmode',
  'Write a review': 'Bewertung schreiben',
  Year: 'Jahr',
  'You added {product} to your shopping cart.': '{product} wurde erfolgreich zu deinem Warenkorb hinzugefügt.',
  'You already have an account. We like it! Use your email address to log in.':
    'Du hast bereits ein Konto erstellt. Lieben wir! Melde dich jetzt mit deiner E-Mail-Adresse an.',
  'You are not authorized, please log in': 'Du bist nicht autorisiert, bitte melde dich an',
  'You can try one of the popular search queries': 'Versuche es mit einer der beliebten Suchanfragen',
  'You can unsubscribe at any time': 'Du kannst dich jederzeit abmelden',
  'You currently have no orders': 'Du hast derzeit keine Bestellung aufgegeben',
  'You have no items in your shopping cart.': 'Es befinden sich keine Artikel in deinem Warenkorb.',
  'You have no items in your wishlist.': 'Es befinden sich keine Artikel auf deinem Wunschzettel.',
  'You have submitted no reviews': 'Du hast keine Bewertungen abgegeben',
  'You haven’t searched for items yet': 'Du hast noch nicht nach Artikeln gesucht.',
  'You must login or register to add items to your wishlist.':
    'Du musst angemeldet oder registriert sein, um Artikel auf deinen Wunschzettel zu setzen.',
  'Your bag is empty': 'Deine Tasche ist leer',
  'Your cart is empty': 'Dein Warenkorb ist leer',
  'Your current email address is': 'Deine aktuelle E-Mail-Adresse lautet',
  'Your e-mail': 'Deine E-Mail',
  'Your rating': 'Deine Bewertung',
  'Your review was submitted!': 'Deine Bewertung wurde übermittelt!',
  'Zip-code': 'Postleitzahl',
  'Zodiac sign': 'Sternzeichen',
  // Translated after MYJE-2429
  Title: 'Titel',
  'Launch:': 'Lancierung: {dateOnline}',
  'Nights sleep': 'Noch {nights} Nächte schlafen',
  'Adding product to wishlist...': 'Produkt wird zur Wunschliste hinzugefügt...',
  'Adding product to cart...': 'Zum Warenkorb hinzufügen...',
  'Login to add to wishlist': 'Anmelden und Artikel auf den Wunschzettel setzen',
  'You have to be logged in to add items to your wishlist.':
    'Du musst eingeloggt sein, damit du deine Lieblingsartikel zu deiner Wunschliste hinzufügen kannst.',
  'Added to your cart!': 'Zum Warenkorb hinzugefügt!',
  'Maximum characters': ' Maximal {amount} Zeichen',
  'See images for the order of engraving': 'Siehe Fotos für die Reihenfolge der Gravur',
  'Personalise your jewellery (capital letters only)': 'Personalisiere deinen Schmuck (nur Großbuchstaben)',
  'Engraving:': 'Gravur:',
  'No results for selected filters': 'Keine Suchergebnisse für ausgewählte Filters',
  'No results for this category': 'Keine Suchergebnisse für diese Kategorie',
  'Please select an available {names}': 'Bitte wähle {names}',
  'Choose an option notification': 'Wähle eine der verfügbaren Optionen',
  customisation: 'Personalisierung',
  'Giftcard price': 'Preis Geschenkgutschein',
  Initial: 'Buchstabe',
  'Birth year': 'Geburtsjahr',
  'Birth month': 'Geburtsmonat',
  'Please only enter letters': 'Bitte nur Buchstaben eingeben',
  'You can enter gift card codes when you proceed to checkout':
    'Gutscheincodes kannst du später bei "bezahlen" eingeben.',
  'Promo code': 'Gutscheincode',
  "Unfortunately, this item is sold out and can't be added to your shopping cart.":
    'Dieses Produkt ist leider ausverkauft und kann deswegen nicht zum Warenkorb hinzugefügt werden.',
  variantMatrixSize: 'deine Größe',
  variantMatrixColor: 'eine Farbe',
  variantMatrixBirthYear: 'ein Geburtsjahr',
  variantMatrixBirthMonth: 'einen Geburtsmonat',
  variantMatrixZodiacSign: 'ein Sternzeichen',
  variantMatrixInitial: 'einen Buchstaben',
  variantMatrixGiftcardPrice: 'den Wert des Geschenkgutscheins',
  variantMatrixEngraving: 'deine persönliche Gravur',
  variantMatrixPhoneModel: 'ein Smarphone-Modell',
  "That's one shiny combination!": 'Was für eine großartige Wahl!',
  'No size chart available': 'Keine Größentabelle verfügbar',
  'Please select {names}': 'Bitte wähle {names}',
  loginToAddToWishlist:
    'Du musst eingeloggt sein, damit du deine Lieblingsartikel zu deiner Wunschliste hinzufügen kannst. {0}',
  'login page': 'Jetzt anmelden',
  Cards: 'Karten',
  'Gift bags': 'Geschenktüten',
  'Gift boxes': 'Geschenkboxen',
  'You added {product} to your wishlist.': '{product} wurde erfolgreich zu deinem Wunschzettel hinzugefügt.',
  NOT_SALABLE: 'Leider ist dieser Artikel nicht mehr vorrätig.',
  'Your opinion counts': 'Deine Meinung zählt',
  Trends: 'Trends',
  Engravable: 'Gravur möglich',
  'Color groups': 'Farbgruppen',
  'Ends in': 'Endet in',
  'Invalid phone number': 'Ungültige Telefonnummer',
  'The field should have at least {amount} characters': 'Das Feld sollte mindestens {amount} Zeichen lang sein',
  'Something went wrong': 'Etwas ist schiefgelaufen',
  PRODUCT_NOT_FOUND:
    'Wir konnten das gesuchte Produkt nicht finden. Es ist möglicherweise nicht verfügbar oder existiert nicht mehr.',
  NOT_SALABLE: 'Dieses Produkt ist derzeit nicht vorrätig.',
  CART_NOT_FOUND:
    'Woops! Dein Warenkorb ist leer. Möglicherweise hast du die Artikel bereits bestellt oder dein Warenkorb existiert nicht mehr.',
  CART_NOT_ACTIVE:
    'Dein Warenkorb ist inaktiv. Bitte füge deine Produkte erneut zum Warenkorb hinzu, um weiterzushoppen.',
  CART_AUTHORIZATION_FAILED:
    'Dein Warenkorb konnte nicht autorisiert werden. Bitte melden dich erneut an, um fortzufahren.',
}
