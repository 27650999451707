import { consola, type ConsolaInstance } from 'consola'
import { toConsolaLevelMapper, type AppLogLevels } from './winston/levels'

import { resolveClientConfig } from './config'
import { useNuxtApp } from '#imports'

export const useAppLogger = (tag: string | undefined = undefined): ConsolaInstance => {

  
  const app = useNuxtApp()

  //Lets find our logger, it should be on the request context in the server
  let logger
  if(app.ssrContext?.event?.context?.logger){
    logger = app.ssrContext.event.context.logger
  }

  const currentComponentName = app.vueApp._component.__name
  const actualTag = tag || currentComponentName

  if(logger){
    if(tag){
      logger = logger.withTag(tag)
    }
    return logger
  }

  //This should be client side
  const level = resolveClientConfig().client.level as AppLogLevels
  const customConsola = consola.create({
    level: toConsolaLevelMapper(level),
    defaults: {
      tag: actualTag,
    },
  })
  return customConsola
}
