import { useRuntimeConfig } from '#imports'
import type { AppLoggerClientOptions, AppLoggerServerOptions } from './types'

export function resolveClientConfig(): AppLoggerClientOptions {
  const config = useRuntimeConfig()
  return config.public.appLogger
}

export function resolveServerConfig(): AppLoggerServerOptions {
  const config = useRuntimeConfig()
  return config.appLogger
}
