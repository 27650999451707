import axios from 'axios'
export default class YoutubeVimeoUrlParser {
  /**
   * Creates an instance of YoutubeVimeoUrlParser.
   *
   * @constructor
   * @this {YoutubeVimeoUrlParser}
   */
  constructor() {
    this.pattern =
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  }

  async checkRemoteVideoExists(url) {
    try {
      const result = await axios.get(url)
      return true
    } catch (error) {
      return false
    }
  }

  /**
   * Returns video meta object.
   *
   * Supported YouTube URL formats:
   * - http://www.youtube.com/watch?v=My2FRPA3Gf8
   * - http://youtu.be/My2FRPA3Gf8
   * - https://youtube.googleapis.com/v/My2FRPA3Gf8
   *
   * Supported Vimeo URL formats:
   * - http://vimeo.com/25451551
   * - http://player.vimeo.com/video/25451551
   * - Also supports relative URLs:
   * - //player.vimeo.com/video/25451551
   *
   * @param {string} url Link to Youtube or Vimeo video.
   * @return {Object} Parsed link. Example: {videoId: "rLMHGjoxJdQ", videoProvider: "youtube"}
   */
  parseUrl(url) {
    const matches = url.match(this.pattern)
    let videoId = null
    let videoProvider = null

    if (matches && matches.length >= 6) {
      videoId = matches[6]
      if (matches[3].indexOf('youtu') > -1) {
        videoProvider = 'youtube'
      } else if (matches[3].indexOf('vimeo') > -1) {
        videoProvider = 'vimeo'
      }
    }

    if (!videoId || !videoProvider) throw new Error('Unsupported URL')

    return { videoProvider, videoId }
  }

  /**
   * Returns video link without protocol and other URL params.
   *
   * @param {string} url Link to Youtube or Vimeo video.
   * @return {string} Link to video. Example: //www.youtube.com/embed/rLMHGjoxJdQ
   */
  getNormalizedSrc(url) {
    const videoObj = this.parseUrl(url)
    let src = null
    if (videoObj.videoProvider === 'youtube') {
      src = `//www.youtube.com/embed/${videoObj.videoId}`
    } else if (videoObj.videoProvider === 'vimeo') {
      src = `//player.vimeo.com/video/${videoObj.videoId}`
    }
    return src
  }

  /**
   * Retrieves the thumbnail URL for a given video URL.
   * @param {string} url - The URL of the video.
   * @returns {Promise<string>} - The thumbnail URL of the video.
   */
  async getVideoThumbnail(url) {
    const videoObj = this.parseUrl(url)
    const logger = useAppLogger('getVideoThumbnail')

    if (videoObj.videoProvider === 'youtube') {
      return `//img.youtube.com/vi/${videoObj.videoId}/maxresdefault.jpg`
    } else if (videoObj.videoProvider === 'vimeo') {
      try {
        const response = await axios.get(`https://vimeo.com/api/v2/video/${videoObj.videoId}.json`)
        const json = response.data
        return json?.[0]?.thumbnail_medium || ''
      } catch (error) {
        logger.error(error)
      }
    }
  }
}
