export const useMagentoImage = () => {
  const config = useRuntimeConfig()

  enum ImageSize {
    Default = 'default',
    Large = 'large',
    Thumbnail = 'thumbnail',
    Original = 'original',
  }

  type Source = 'bloomreach' | 'magento'

  const transformImageUrlToSize = (
    url: string,
    size: ImageSize = ImageSize.Default,
    source: Source = 'magento',
  ): string => {
    if (!url) return ''

    const sizeHashes = {
      default: config.public.imageHashDefault,
      thumbnail: config.public.imageHashThumbnail,
      large: config.public.imageHashLarge,
      original: config.public.imageHashOriginal,
    }

    const sizeHashesBloomreach = {
      default: config.public.imageHashBloomreachDefault,
      thumbnail: config.public.imageHashBloomreachThumbnail,
      large: config.public.imageHashBloomreachLarge,
      original: config.public.imageHashBloomreachOriginal,
    }

    const hash = source === 'magento' ? sizeHashes[size] : sizeHashesBloomreach[size]

    if (url?.indexOf('cache/') > 0) {
      if (size === 'original') {
        return url?.replace(/cache\/([a-z0-9]+)\//, '')
      }

      return url?.replace(/cache\/([a-z0-9]+)\//, `cache/${hash}/`)
    }
    return url?.replace('catalog/product/', `catalog/product/cache/${hash}/`)
  }

  return {
    transformImageUrlToSize,
    ImageSize,
  }
}
