import type { PageModel } from '@bloomreach/spa-sdk'
import setPageData from './helpers/setPageData'
import { defineStore } from 'pinia'

export interface HrefLang {
  rel: string
  language: string
  url: string
}

export const usePageStore = defineStore('page', () => {
  const pageType: Ref<'BLOOMREACH' | 'PRODUCT' | 'CATEGORY' | 'SEARCH'> = ref('BLOOMREACH')
  const product: Ref<any> = ref()
  const hrefLangs: Ref<HrefLang[]> = ref([])
  const routeData: Ref<Record<string, any>> = ref({
    type: '',
  })
  const totalProducts: Ref<number> = ref(0)
  const loaded: Ref<boolean> = ref(false)
  const pageLayout: Ref<string> = ref('one-column')
  const quickFilters: Ref<Array<any>> = ref([])
  const configuration: Ref<any> = ref({})
  const bloomreachPage: Ref<Record<string, PageModel>> = ref({})
  const pageData: Ref<Record<string, any>> = ref({})

  return {
    pageType,
    product,
    hrefLangs,
    totalProducts,
    loaded,
    pageLayout,
    quickFilters,
    configuration,
    bloomreachPage,
    routeData,
    pageData,
    setPageData,
  }
})

export default usePageStore
