import type { CategoryList } from '~/stores/useCategoryStore/categoryStore'

export const getCategoryListCommand = {
  execute: async (params): Promise<CategoryList[]> => {
    const { $sdk } = useNuxtApp()
    const localePath = useLocalePath()
    const { data } = await $sdk.magento.categoryList({ filters: params }, { categoryList: 'category-list' })

    return (
      data?.categoryList?.map((category) => ({
        ...category,
        url: localePath(`/${category.url_path}${category.url_suffix}`),
      })) || []
    )
  },
}
