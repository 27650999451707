import type { Cart } from '@vue-storefront/magento-types'

export const removeCouponCommand = {
  execute: async ({ currentCart }) => {
    const { $sdk } = useNuxtApp()
    console.debug('[Magento]: Remove coupon from cart', { currentCart })

    const { data, errors } = await $sdk.magento.removeCouponFromCart(
      { cart_id: currentCart.id },
      { removeCouponFromCart: 'remove-coupon-from-cart' },
    )

    //@TODO - remove this when we can use actual custom queries
    const cartStore = useCartStore()

    cartStore.setCart(data?.removeCouponFromCart.cart)

    console.debug('[Result]:', { data })

    return {
      updatedCart: data?.removeCouponFromCart?.cart as unknown as Cart,
      updatedCoupon: { code: '' },
      errors,
    }
  },
}
