import type { Cart } from '@vue-storefront/magento-types'

export const applyCouponCommand = {
  execute: async ({ currentCart, couponCode }) => {
    const { $sdk } = useNuxtApp()

    console.debug('[Magento]: Apply coupon on cart', {
      couponCode,
      currentCart,
    })

    const { data, errors } = await $sdk.magento.applyCouponToCart(
      {
        cart_id: currentCart.id,
        coupon_code: couponCode,
      },
      {
        applyCouponToCart: 'apply-coupon-to-cart',
      },
    )

    //@TODO - remove this when we can use actual custom queries
    const cartStore = useCartStore()
    if (data?.applyCouponToCart) cartStore.setCart(data.applyCouponToCart.cart)

    console.debug('[Result]:', { data, errors })

    return {
      updatedCart: data.applyCouponToCart?.cart as unknown as Cart,
      updatedCoupon: { code: couponCode },
      errors,
    }
  },
}
