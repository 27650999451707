import gql from 'graphql-tag'
import wishlistFields from './wishlistFragment.gql.js'
/**
 * GraphQL Query that mutates the products in a customer wishlist.
 */
export default gql`
  mutation updateProductsInWishlist($wishlistId: ID!, $wishlistItems: [WishlistItemUpdateInput!]!) {
    updateProductsInWishlist(wishlistId: $wishlistId, wishlistItems: $wishlistItems) {
      wishlist {
        id
        items_count
        items_v2(currentPage: 1, pageSize: 10) {
          ...wishlistFields
        }
      }
    }
  }
  ${wishlistFields}
`
