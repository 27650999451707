import { defineStore } from 'pinia'

export interface PrettyUrlFilter {
  label: string
  slug: string
  type: string
}

export const usePrettyUrlStore = defineStore('prettyUrl', () => {
  const state = reactive({
    prettyUrls: <PrettyUrlFilter[]>[],
    selectedPrettyUrls: [],
  })

  return {
    ...toRefs(state),
  }
})

export default usePrettyUrlStore
