import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/en'
import '@formatjs/intl-numberformat/locale-data/de'
import '@formatjs/intl-numberformat/locale-data/fr'
import '@formatjs/intl-numberformat/locale-data/nl'
import { currencyCookieName } from '~/enums/cookieNameEnum'

export default defineNuxtPlugin((nuxtApp: any) => {
  const formatCurrency = (value: number | string): string => {
    const { locale, numberFormats } = nuxtApp.$i18n
    const options: Intl.NumberFormatOptions = {
      currency: useCookie(currencyCookieName).value || numberFormats.value[locale.value]?.currency.currency || 'EUR',
    }

    if (typeof value === 'string') {
      value = Number(value)
    }

    return new Intl.NumberFormat(locale.value, {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      ...options,
    }).format(value)
  }

  return {
    provide: {
      formatCurrency,
    },
  }
})
