import revive_payload_client_4sVQNw7RlN from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/my-jewellery/code/frontend-nuxt3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import base64_aUhzL1du9B from "/builds/my-jewellery/code/frontend-nuxt3/plugins/base64.ts";
import brxm_QnHhk9NSee from "/builds/my-jewellery/code/frontend-nuxt3/plugins/brxm.ts";
import brxmEndpointResolver_eNyXYBE1xj from "/builds/my-jewellery/code/frontend-nuxt3/plugins/brxmEndpointResolver.ts";
import datadog_rum_client_RByIzBtkfe from "/builds/my-jewellery/code/frontend-nuxt3/plugins/datadog-rum.client.ts";
import exponeaAuthentication_2pY3x6dxoI from "/builds/my-jewellery/code/frontend-nuxt3/plugins/exponeaAuthentication.ts";
import exponeaHelpers_client_upm4hcx6XL from "/builds/my-jewellery/code/frontend-nuxt3/plugins/exponeaHelpers.client.ts";
import formatCurrency_Ud4HNt2xAs from "/builds/my-jewellery/code/frontend-nuxt3/plugins/formatCurrency.ts";
import lazy_hydration_ISm2rlWKO7 from "/builds/my-jewellery/code/frontend-nuxt3/plugins/lazy-hydration.ts";
import purifyHtml_8yma0UF2cu from "/builds/my-jewellery/code/frontend-nuxt3/plugins/purifyHtml.ts";
import sdk_R939RdCrQq from "/builds/my-jewellery/code/frontend-nuxt3/plugins/sdk.ts";
import veeValidate_U1l0qzVbBC from "/builds/my-jewellery/code/frontend-nuxt3/plugins/veeValidate.ts";
import z_initialization_bp70Wz83vd from "/builds/my-jewellery/code/frontend-nuxt3/plugins/z.initialization.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_t2GMTTFnMT,
  i18n_yfWm7jX06p,
  base64_aUhzL1du9B,
  brxm_QnHhk9NSee,
  brxmEndpointResolver_eNyXYBE1xj,
  datadog_rum_client_RByIzBtkfe,
  exponeaAuthentication_2pY3x6dxoI,
  exponeaHelpers_client_upm4hcx6XL,
  formatCurrency_Ud4HNt2xAs,
  lazy_hydration_ISm2rlWKO7,
  purifyHtml_8yma0UF2cu,
  sdk_R939RdCrQq,
  veeValidate_U1l0qzVbBC,
  z_initialization_bp70Wz83vd
]