export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp()
  const { locale, defaultLocale } = $i18n
  const config = useRuntimeConfig()

  const resolveEndPoint = () => {
    const endpoints = config.public.brxmEndpoints
    if (endpoints[locale.value as keyof typeof endpoints]) {
      return endpoints[locale.value as keyof typeof endpoints]
    }
    return endpoints[defaultLocale as keyof typeof endpoints]
  }

  const resolver = {
    resolvePagesEndPoint: (path: string) => {
      return resolveEndPoint() + '/pages' + (path?.startsWith('/') ? path : `/${path}`)
    },
    resolveDocumentsEndPoint: (path: string) => {
      return resolveEndPoint() + '/documents' + (path?.startsWith('/') ? path : `/${path}`)
    },
  }

  return {
    provide: {
      brxmEndpointResolver: resolver,
    },
  }
})
