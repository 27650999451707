import type { Cart, RemoveItemFromCartInput } from '@vue-storefront/magento-types'

export const removeItemCommand = {
  execute: async ({ currentCart, product }) => {
    const { $sdk } = useNuxtApp()
    console.debug('[Magento]: Remove item from cart', {
      product,
      currentCart,
    })

    const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid)

    if (!item) {
      return
    }

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: currentCart.id,
      cart_item_uid: item.uid,
    }

    const { data, errors } = await $sdk.magento.removeItemFromCart(removeItemParams, {
      removeItemFromCart: 'remove-item-from-cart',
    })

    //@TODO - remove this when we can use actual custom queries
    const cartStore = useCartStore()
    cartStore.setCart(data?.removeItemFromCart?.cart)

    console.debug('[Result]:', { data, errors })

    if (!data?.removeItemFromCart && errors?.length) {
      throw errors[0]
    }

    // eslint-disable-next-line consistent-return
    return data.removeItemFromCart.cart as unknown as Cart
  },
}
