/* eslint-disable  */

export default {
  'Page {currentPage} of {totalPages}': 'Page {currentPage} of {totalPages}',
  'A 10% discount on your next purchase?': '10% discount on your next purchase?',
  'A customer with the same email address already exists in an associated website.':
    'A customer with the same email address already exists on an associated website.',
  'About us': 'About us',
  Account: 'Account',
  'Account dashboard': 'Account dashboard',
  'Account Details': 'Account Details',
  'Add new address': 'Add new address',
  'Add review': 'Add review',
  'Add the address': 'Add address',
  'Add to cart': 'Add to cart',
  'Quick add to cart': 'Add to cart',
  Adding: 'Adding',
  'Added!': 'Added!',
  'Add to compare': 'Add to compare',
  'Add to Wishlist': 'Add to Wishlist',
  'Added to cart': 'Added to cart',
  'Additional Information': 'Additional Information',
  'Addresses details': 'Address details',
  'Adjusted opening hours': 'Adjusted opening hours',
  'Allow order notifications': 'Allow order notifications',
  and: 'and',
  Apply: 'Apply',
  'Attention!': 'Attention!',
  'Available again soon': 'Available again soon',
  'Available from date': 'Available from {date}',
  'BACK SOON': 'BACK SOON',
  'Back to home': 'Back to home',
  'Back to homepage': 'Back to homepage',
  'Back to top': 'Back to top',
  'Be aware of upcoming sales and events. Receive gifts and special offers!':
    'Be aware of upcoming sales and events. Receive gifts and special offers',
  'Your feedback means a lot to us: write a review!': 'Your feedback means a lot to us: write a review!',
  Billing: 'Billing',
  'Billing address': 'Billing address',
  'Birthday incorrect': 'Invalid birthday',
  Brand: 'Brand',
  'By: {author}': 'By: {author}',
  Campaign: 'Campaign',
  Cancel: 'Cancel',
  Canceled: 'Cancelled',
  Cart: 'Cart',
  Categories: 'Categories',
  Change: 'Change',
  'Change password': 'Change password',
  'Change password your account': 'Follow the instructions to change the password and get access to your account',
  'Change to grid view': 'Change to grid view',
  'Change to list view': 'Change to list view',
  'Choose an option': 'Choose an option',
  'Choose your language': 'Choose your language',
  City: 'City',
  'Clear all': 'Clear all',
  Close: 'Close',
  'Close search': 'Close search',
  closed: 'closed',
  'Cocktail & Party': 'Cocktail & Party',
  Color: 'Colour',
  'Colorful summer dresses are already in store': 'Colourful summer dresses are in store already',
  Colors: 'Colours',
  'COMING SOON': 'COMING SOON',
  'Coming soon form description': 'Enter your email address to receive an email on release day!',
  'Coming soon form title': 'Receive an email when this item is released!',
  'Commercial information': 'and agree to receive personalised commercial information from My Jewellery by email',
  'Contact Details': 'Contact Details',
  'Contact details updated': 'Keep your addresses and contact details updated.',
  'Contact us': 'Contact us',
  'Continue shopping': 'Continue shopping',
  'Continue to billing': 'Continue to billing',
  'Continue to payment': 'Continue to payment',
  'Continue to shipping': 'Continue to shipping',
  'Cookies Link': '/privacy-cookies',
  'Cookies Policy': 'Cookies Policy',
  Country: 'Country',
  'Create an account': 'Create an account',
  'Current Password': 'Current Password',
  'Customer Reviews': 'Customer Reviews',
  'Customer service': 'Customer service',
  Date: 'Date',
  'Date of birth': 'Date of birth',
  Day: 'Day',
  Default: 'Default',
  'Default Billing Address': 'Default Billing Address',
  'Default Shipping Address': 'Default Shipping Address',
  Delete: 'Delete',
  'Delivery & Payment': 'Delivery & Payment',
  'Delivery & Returns': 'Delivery & Returns',
  Departments: 'Departments',
  Description: 'Description',
  'Details and status orders':
    'Go to the online store to check your order details & status, cancel your order or request a return.',
  Discount: 'Discount',
  'Discover our new items!': 'Discover our new items!',
  Done: 'Done',
  'Done. You’ve subscribed': 'Done. You’ve subscribed',
  Download: 'Download',
  'Download all': 'Download all',
  'Download our application to your mobile': 'Download our application to your mobile',
  Dresses: 'Dresses',
  'Ear piercing in': 'ear piercing in',
  'Eco Sandals': 'Eco Sandals',
  Edit: 'Edit',
  'Email address': 'Email address',
  'Email you are using to sign in': 'Email you used to sign in',
  'Empty bag': 'Empty bag',
  'Enjoy your free account': 'Enjoy these perks with your free account!',
  'Enter promo code': 'Enter promo code',
  'Enter your email address': 'Enter your email address',
  'Existing customers': 'Existing customers',
  'Extended overview': 'Extended overview',
  'Fashion to take away': 'Fashion to take away',
  Features: 'Features',
  Feedback: 'Your feedback is important to us, please tell us how we can improve.',
  'Feel free to edit': 'Feel free to edit any of your details below so your account is always up-to-date',
  Filters: 'Filters',
  'Filters & sorting': 'Filters & sorting',
  'Find out more': 'Discover more',
  'Find stunning women’s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.':
    'Find stunning women’s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favourite brands.',
  'First Name': 'First Name',
  Fit: 'Fit',
  'Forgot Password': 'Forgot your password? Reset it.',
  'Forgot Password Modal Email': 'Email used to sign in:',
  'Forgot Your Password?': 'Forgot your password?',
  forgotPasswordConfirmation:
    'Thanks! If there is an account registered to this {0} email address, you’ll receive an email with a password reset link in your inbox.',
  'Forgotten password?': 'Forgotten password?',
  'Free shipping': 'Free shipping',
  'Get inspired': 'Get inspired',
  'Gift? Add a gift wrap!': 'Gift? Add gift wrapping!',
  'Go back': 'Go back',
  'Go back shopping': 'Go back shopping',
  'Go back to shop': 'Go back to shop',
  'Go to checkout': 'Go to checkout',
  'Go To Product': 'Go to product',
  'Grand Total': 'Grand Total',
  Guarantee: 'Guarantee',
  Help: 'Help',
  'Help & FAQs': 'Help & FAQs',
  hide: 'hide',
  'Highest price': 'Highest price ',
  Home: 'Home',
  'House/Apartment number': 'House/Apartment number',
  'I agree to': 'I agree to',
  'I confirm subscription': 'I confirm subscription',
  'I have read and understand the': 'I have read and understand the',
  'I want to create an account': 'I want to create an account',
  'In stock': 'In stock',
  'Info after order':
    'Log into your account with your email address and password. Edit your profile data, check transaction history and amend your newsletter subscription in your account.',
  Instruction1: 'Take care of me',
  Instruction2: 'Just here for the care instructions?',
  Instruction3: 'Yeah, we thought so',
  'Invalid email': 'Invalid email',
  'Invalid Password Message':
    'Your password must be at least 8 characters long and contain at least: 1 uppercase or lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)',
  'It was not possible to request a new password, please check the entered email address.':
    'We were unable to create a new password, please verify the email address that was entered. ',
  Item: 'Item',
  Items: 'Items',
  'Keep me updated': 'Keep me updated',
  Kidswear: 'Kidswear',
  'Last Name': 'Last Name',
  'Learn more': 'Learn more',
  'Let’s create an account. It has many advantages, such as order tracking, fast checkout and registration of multiple addresses.':
    'Let’s create an account. So you can track your order, enjoy fast checkout and register multiple delivery addresses',
  'Let’s start now – we’ll help you': 'Let’s start now – we’ll help you.',
  'Linen Dresses': 'Linen Dresses',
  'Load more reviews': 'Load more reviews',
  'Log in to save your favourites!': 'Log in to save your favourites!',
  'Log into your account': 'Log into your account',
  'Log out': 'Log out',
  Login: 'Login',
  'login in to your account': 'Log in to your account',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    'Looks like you haven’t added any items to your shopping cart yet. Start shopping to fill it!',
  'Lowest price': 'Lowest price',
  'Make an order': 'Place an order',
  'Manage Addresses': 'Manage addresses',
  'Manage addresses':
    'Manage all the addresses you want (work place, home address...) This way you won"t have to enter the address manually for each order.',
  'Manage billing addresses':
    'Manage all the billing addresses you want (work place, home address...) This way you won"t have to enter the billing address manually for each order.',
  'Manage shipping addresses':
    'Manage all the shipping addresses you want (work place, home address...) This way you won"t have to enter the shipping address manually for each order.',
  'Match it with': 'Related products',
  Material: 'Material',
  'Men fashion': 'Men fashion',
  Menu: 'Menu',
  Month: 'Month',
  'Month flower': 'Month flower',
  more: 'more',
  'My Account': 'My Account',
  'My billing and shipping address are the same': 'My billing and shipping address are the same',
  'My Cart': 'My Cart',
  'My newsletter': 'My newsletter',
  'My orders': 'My orders',
  'My profile': 'My profile',
  'My reviews': 'My reviews',
  'My wishlist': 'My wishlist',
  Name: 'Name',
  'Name A-Z': 'Name A-Z',
  'New customer': 'New customer',
  'New Password': 'New Password',
  'New Products': 'New Products',
  Newest: 'Lastest ',
  'No account': 'Don’t have an account yet?',
  'No products for query "{keyword}"': 'No products for query "{keyword}"',
  'No products were found for the search query':
    'Hang on, we’re still retrieving the best search results for you. Press enter of click the magnifying glass to view all search results for "<strong>{query}</strong>".',
  'No results for “{keyword}”': 'No results for “{keyword}”',
  'Notify me on release': 'Notify me upon release',
  'Open search': 'Open search',
  'Opening hours': 'Opening hours',
  option: 'option | options',
  or: 'or',
  'or fill the details below': 'or fill the details below',
  'Order {price} more for free shipping': 'Order {price} extra for free shipping',
  'Order details': 'Order details',
  'Order history': 'Order history',
  'Order information': 'Order information',
  'Order No.': 'Order No.',
  'Order summary': 'Order summary',
  'Original price': 'Price',
  'Other products you might like': 'Other products you might like',
  'Out of stock': 'Out of stock',
  Overview: 'Overview',
  Password: 'Password',
  'Password change': 'Password change',
  'Password Changed': 'Password successfuly changed, you can now go back to the homepage and sign in.',
  'Passwords don’t match': 'Passwords don’t match',
  'Pay for order': 'Pay for order',
  Payment: 'Payment',
  'Payment & Delivery': 'Payment & Delivery',
  'Payment & shipping methods': 'Payment & shipping methods',
  'Payment method': 'Payment method',
  'Payment methods': 'Payment methods',
  'Personal data': 'Personal data',
  'Personal details': 'Personal details',
  'Personal Details': 'Personal Details',
  'Personal information': 'Sign-in information',
  'Phone number': 'Phone number',
  'Please enter your email address below to receive a password reset link.':
    'Please enter your email address below to receive a password reset link.',
  'Please select a country first': 'Please select a country first',
  'Please select the {names} below': 'Please select {names} below',
  'Please select a color first': 'Please select a color first',
  'Please select the option above first': 'Please select the option above first',
  'Please type your current password to change your email address.':
    'Please sign in with your current password to change your email address.',
  Price: 'Price',
  Privacy: 'Privacy',
  'Privacy Link': '/en/privacy-cookies',
  'Privacy Policy': 'Privacy Policy',
  Product: 'Product',
  product: 'product | products',
  'Product recommendations unknown user': 'Discover our bestsellers',
  'Product recommendations with name': 'We think you’ll like these, {firstName}',
  'Product recommendations with name - male': 'Discover more gift inspiration, {firstName}',
  'Product recommendations without name': 'We think you’ll like these',
  'Product recommendations without name - male': 'Discover more gift inspiration',
  'Product suggestions': 'Product suggestions',
  Products: 'Products',
  'Products found': 'Products found',
  'Entre ton code de réduction': 'Promo code',
  'Purchase terms': 'Purchase terms',
  'Quality in the details': 'Quality in the details',
  Quantity: 'Quantity',
  Rating: 'Rating',
  'Read all reviews': 'Read all reviews',
  'Read and understand': 'I have read and understand the',
  'Read less': 'Read less',
  'Read more': 'Read more',
  'Read reviews': 'Read reviews',
  'Reading time: {readingTime} minutes': 'Read time: {readingTime} minutes',
  Recommended: 'Recommended',
  Register: 'Register',
  'Register today': 'Register today',
  'Remove Address': 'Remove Address',
  'Remove from Wishlist': 'Remove from Wishlist',
  'Repeat Password': 'Repeat password',
  'Reset Password': 'Reset password',
  'Return home': 'Return home',
  Returns: 'Returns',
  Review: 'Review',
  'Review my order': 'Review my order',
  Reviews: 'Reviews',
  SALE: 'SALE',
  'Same as shipping address': 'Same as shipping address',
  'Save changes': 'Save changes',
  'Save for later': 'Save for later',
  'Save Password': 'Save Password',
  Search: 'Search',
  'Search for items': 'Search for items',
  'Search for products, ...': 'Search for products, ...',
  'Search for products, categories, ...': 'Search for products, categories, ...',
  'Search results': 'Search results',
  'Search results for: “{keyword}”': 'Search results for: “{keyword}”',
  Season: 'Season',
  'Sections that interest you': 'Categories that interest you',
  'See all': 'See all',
  'See all products': 'See all products',
  'See all results': 'See all results',
  'See our new items link': '/en/new.html',
  'See our new items!': 'Discover our new items!',
  'Select payment method': 'Select payment method',
  'Select shipping method': 'Select shipping method',
  'Send my feedback': 'Send my feedback',
  'Set as default billing': 'Set as default billing',
  'Set as default shipping': 'Set as default shipping',
  'Set up newsletter':
    'Set up your newsletter and we will send you information about new products and trends from your favourite categories every week.',
  'Share your experience and help others make the right decision':
    'Share your experience and help others make the right decision',
  'Share your look': 'Share your look',
  'Share Your Look': 'Share Your Look',
  Shipping: 'Shipping',
  'Shipping address': 'Shipping address',
  'Shipping details': 'Shipping details',
  'Shipping method': 'Shipping method',
  'Shop now': 'Shop now',
  'Show all': 'Show all',
  'Show all results': 'Show all results',
  'Show less shop-in-shops': 'Show less shop-in-shops',
  'show more': 'show more',
  'Show more products': 'Show more products',
  'Show more shop-in-shops': 'Show more shop-in-shops',
  'Show on page': 'Show on page',
  'Show previous products': 'Show previous products',
  'Sign in': 'Sign in',
  'sign me up': 'sign me up',
  'Sign Up for Newsletter':
    'Yes, I want to sign up for e-mail to receive a 10% discount code, updates on new arrivals and the latest deals.',
  'Sign-in information': 'Sign-in information',
  Size: 'Size',
  'Size chart': 'Size chart',
  'Size guide': 'Size guide',
  'Sku color': 'Color',
  'Sold out form description':
    'Enter your email address and receive an email as soon as your preferred size or colour is back in stock!',
  'Sold out form title': 'This size or colour is currently out of stock',
  'Sorry, the page you are looking for does not seem to exist anymore.':
    'Sorry, the page you are looking for does not seem to exist anymore.',
  'Sort by': 'Sort by',
  'Sort: Default': 'Default',
  'Sort: Name A-Z': 'Name A-Z',
  'Sort: Name Z-A': 'Name Z-A',
  'Sort: Price from high to low': 'Price from high to low',
  'Sort: Price from low to high': 'Price from low to high',
  'Start shopping': 'Start shopping',
  'State/Province': 'State/Province',
  'Street Name': 'Street Name',
  'Style profile': 'Style',
  Subscribe: 'Subscribe',
  'Subscribe to newsletter': 'Subscribe to newsletter',
  'Subscribe to Newsletters': 'Subscribe to Newsletters',
  'Subscribe your friend!': 'Subscribe your friend!',
  subscribeToNewsletterModalContent:
    'After signing up for the newsletter, you will receive special offers and messages from My Jewellery via email. We will never sell or share your email address with any third parties. Please see our {0}.',
  Subtotal: 'Subtotal',
  'Subtotal price': 'Subtotal price',
  'Successful placed order':
    'You have successfully placed your order, simply check the status of your order by using our delivery status feature. You’ll receive an order confirmation by email with details of your order and a link to track its progress.',
  Summary: 'Summary',
  'SUMMER COLLECTION {year}': 'SUMMER COLLECTION {year}',
  'Summer Sandals': 'Summer Sandals',
  'T-Shirts': 'T-Shirts',
  Tax: 'Tax',
  'Terms and conditions': 'Terms and conditions',
  'Thank You Inbox':
    'If the message hasn’t arrived in your inxo, please try another email address you might’ve used to register.',
  'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.':
    'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.',
  'The email field must be a valid email': 'The email field must be a valid email address',
  'The field should have at least 2 characters': 'The field should have at least 2 characters',
  'The field should have at least 4 characters': 'The field should have at least 4 characters',
  'The field should have at least 8 characters': 'The field should have at least 8 characters',
  'The office life': 'The office life',
  'The Office Life': 'The Office Life',
  'The user account data was successfully updated!': 'User data was successfully updated',
  'There are no shipping methods available for this country. We are sorry, please try with different country.':
    'There are no shipping methods available for this country. We are sorry, please try again for a different country.',
  'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.':
    'There was an error while retrieving shipping methods, sorry about that! Please try again with different shipping details.',
  'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.':
    'There was an error while selecting this shipping method, sorry about that! Please try again with a different shipping method.',
  'These are our bestsellers': 'These are our bestsellers',
  'These are your last viewed items': 'These are your last viewed items',
  'This field is required': 'This field is required',
  'This is a required field.': 'This is a required field.',
  Total: 'Total',
  'Total items': 'Total items',
  'Total price': 'Total price',
  'Update password': 'Update password',
  'Update personal data': 'Update personal data',
  'Update the address': 'Update the address',
  'Use your personal data':
    ' Privacy is incredibly important to us at My Jewellery so we are committed to protecting the personal data of our users. Learn more about how we care and use your personal date in the...',
  'User Account': 'User Account',
  View: 'View',
  'View all items on your wishlist': 'View all items on your wishlist',
  'View details': 'View details',
  'View our newest items & items that have been saved especially for you below! Or shop from one of our categories.':
    'View our newest items & items that have been saved especially for you! Or shop from one of our categories.',
  'Vue Storefront Next': 'Vue Storefront Next',
  'We believe you might like these items': 'We think you might like these items',
  'We haven’t found any results for given phrase': 'We were unable to find any results with that search query',
  'We think you’ll like these items': 'We think you’ll like these items',
  'Who we are': 'Who we are',
  Wishlist: 'Wishlist',
  'Women fashion': 'Women’s fashion',
  'Write a review': 'Write a review',
  Year: 'Year',
  'You added {product} to your shopping cart.': 'You added {product} to your shopping cart.',
  'You already have an account. We like it! Use your email address to log in.':
    'You already have an account, we love that! Use your email address to log in.',
  'You are not authorized, please log in': 'You are not authorised, please log in',
  'You can try one of the popular search queries': 'Try one of these popular search queries',
  'You can unsubscribe at any time': 'You can unsubscribe at any time',
  'You currently have no orders': 'You currently have no orders',
  'You have no items in your shopping cart.': 'You have no items in your shopping cart.',
  'You have no items in your wishlist.': 'You have no items in your wishlist.',
  'You have submitted no reviews': 'You have submitted no reviews',
  'You haven’t searched for items yet': 'You haven’t searched for items yet.',
  'You must login or register to add items to your wishlist.':
    'You must login or register to add items to your wishlist.',
  'Your bag is empty': 'Your bag is empty',
  'Your cart is empty': 'Your cart is empty',
  'Your current email address is': 'Your current email address is',
  'Your e-mail': 'Your email address',
  'Your rating': 'Your rating',
  'Your review was submitted!': 'Your review was submitted!',
  'Zip-code': 'Zip code',
  'Zodiac sign': 'Zodiac sign',
  // Translated after MYJE-2429
  Title: 'Title',
  'Launch:': 'Launch: {dateOnline}',
  'Nights sleep': '{nights} more nights sleep',
  'Adding product to wishlist...': 'Adding product to wishlist...',
  'Adding product to cart...': 'Adding product to cart...',
  'Login to add to wishlist': 'Login to add to wishlist',
  'You have to be logged in to add items to your wishlist.': 'You have to be logged in to add items to your wishlist.',
  'Added to your cart!': 'Added to your cart!',
  'Maximum characters': 'Maximum {amount} characters',
  'See images for the order of engraving': 'See images for the order of engraving',
  'Personalise your jewellery (capital letters only)': 'Personalise your jewellery (capital letters only)',
  'Engraving:': 'Engraving:',
  'No results for selected filters': 'No results for selected filters',
  'No results for this category': 'No results for this category',
  'Please select an available {names}': 'Please select an available {names}',
  'Choose an option notification': 'Choose an option',
  customisation: 'customisation',
  'Giftcard price': 'Giftcard price',
  'Birth year': 'Birth year',
  'Birth month': 'Birth month',
  Initial: 'Initial',
  'Phone model': 'Phone model',
  'Please only enter letters': 'Please only enter letters',
  'You can enter gift card codes when you proceed to checkout':
    'Gift card codes can be filled out at the checkout stage',
  'Promo code': 'Promo code',
  "Unfortunately, this item is sold out and can't be added to your shopping cart.":
    "Unfortunately, this item is sold out and can't be added to your shopping cart.",
  variantMatrixSize: 'your size',
  variantMatrixColor: 'a colour',
  variantMatrixBirthYear: 'a birth year',
  variantMatrixBirthMonth: 'a birth month',
  variantMatrixZodiacSign: 'a zodiac sign',
  variantMatrixInitial: 'an initial',
  variantMatrixGiftcardPrice: 'a gift card value',
  variantMatrixEngraving: 'your personal engraving',
  variantMatrixPhoneModel: 'a phone model',
  "That's one shiny combination!": "That's one shiny combination!",
  'No size chart available': 'No size chart available',
  'Please select {names}': 'Please select {names}',
  loginToAddToWishlist: 'You must login or register to add items to your wishlist, go to {0}',
  'login page': 'login page',
  Cards: 'Cards',
  'Gift bags': 'Gift bags',
  'Gift boxes': 'Gift boxes',
  'You added {product} to your wishlist.': 'You added {product} to your wishlist.',
  NOT_SALABLE: 'Unfortunately, this item is no longer in stock.',
  'Your opinion counts': 'Reviews',
  Trends: 'Trends',
  Engravable: 'Engravable',
  'Color groups': 'Color groups',
  'Ends in': 'Ends in',
  'Invalid phone number': 'Invalid phone number',
  'The field should have at least {amount} characters': 'The field should have at least {amount} characters',
  'Something went wrong': 'Something went wrong',
  PRODUCT_NOT_FOUND: 'We couldn’t find the product you’re looking for. It may not be available or it doesn’t exist.',
  NOT_SALABLE: 'Sorry, this product is currently out of stock and can’t be purchased.',
  CART_NOT_FOUND:
    'Oops! We couldn’t find your cart. It may have been converted to an order or it no longer exists. Please try again.',
  CART_NOT_ACTIVE: 'Your cart is inactive. Please try again.',
  CART_AUTHORIZATION_FAILED: 'We couldn’t authorize your cart. Please try again.',
}
