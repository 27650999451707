import customAttributeMetadataQuery from '~/magentoMiddleware/customQueries/getCustomAttributeMetadata.gql'
import type { Attribute } from '../useProductStore'

export const getAttributeData = async () => {
  const logger = useAppLogger("getAttributeData")
  try {
    const { $sdk } = useNuxtApp()
    const { data } = await $sdk.magento.customQuery({
      query: customAttributeMetadataQuery,
    })

    logger.debug(`useCustomAttributeMetadata/search/result`, data.customAttributeMetadata)
    
    return data.customAttributeMetadata?.items as Attribute[]
  } catch (err) {
    logger.error(`useCustomAttributeMetadata/search/error`, err)
    return undefined
  }
}
