import { buildModule, initSDK, middlewareModule, SdkHttpError } from '@vue-storefront/sdk'
import type { Endpoints } from '@vue-storefront/magento-api'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig(nuxtApp)
  const { locale } = nuxtApp.$i18n

  const config = {
    apiUrl: runtimeConfig.public.magentoMiddlewareUrl,
    httpClient: async (url, params) => {
      // Custom http client so we can add the store code to the server-side request
      try {
        const headers = {
          'Content-Type': 'application/json',
        }

        // Add the store server side, because cookies aren't available in the server
        if (import.meta.server) {
          headers.Cookie = `vsf-store=con_${locale.value.slice(0, 2)};`
        }

        return await $fetch(url, {
          method: 'POST',
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
          credentials: 'include',
        })
      } catch (err: any) {
        throw new SdkHttpError({
          statusCode: err?.response?.status || 500,
          message: err?.response?.data?.message || err.message,
          cause: err,
        })
      }
    },
  }

  if (import.meta.server) {
    runtimeConfig.ssrApiUrl = runtimeConfig.magentoMiddlewareSsrUrl
  }

  const sdkConfig = {
    magento: buildModule(middlewareModule<Endpoints>, config),
  }

  const sdk = initSDK<typeof sdkConfig>(sdkConfig)

  return {
    provide: {
      sdk,
    },
  }
})
