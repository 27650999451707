import { cartCookieName } from '~/enums/cookieNameEnum'

export const loadTotalQtyCommand = {
  execute: async () => {
    console.debug('[Magento]: Load cart total qty')
    const cartCookie = useCookie(cartCookieName)
    const { $sdk } = useNuxtApp()

    const cartId = cartCookie.value?.indexOf('|') ? cartCookie.value.split('|').pop() : cartCookie.value
    if (cartId) {
      const { data }: any = await $sdk.magento.cartTotalQty(cartId)
      return data?.cart?.total_quantity ?? 0
    }

    return 0
  },
}
