import { defineStore } from 'pinia'
import useCache from '~/composables/useCache'
import type { PageModel } from '@bloomreach/spa-sdk'

type Usp = {
  icon: string
  message: string
}

export const usePCPStore = defineStore('pcp', () => {
  const logger = useAppLogger("usePCPStore")
  const {
    $brxmEndpointResolver,
    $i18n: { locale },
  } = useNuxtApp()
  const { get: getCache, set: setCache } = useCache('PCP')
  const { breadcrumbs } = storeToRefs(useBreadcrumbsStore())

  const state = reactive({
    usps: <Record<string, Usp[]>>{},
    currentUsps: <Usp[]>[],
    loading: <boolean>false,
  })

  const fetchBloomreachPage = async (relativePageUrl: string): Promise<PageModel | undefined> => {
    try {
      return await $fetch($brxmEndpointResolver.resolvePagesEndPoint(relativePageUrl))
    } catch (error) {
      logger.warn(`Failed to get bloomreach page: ${relativePageUrl}`, error)
    }
  }

  const mapDataToUsps = (data: any): Usp[] => {
    const messages = Object.keys(data)
      .filter((key) => key.includes('message'))
      .sort()
      .map((key) => data[key])

    const icons = Object.keys(data)
      .filter((key) => key.includes('icon'))
      .sort()
      .map((key) => data[key])

    const mappedUsps: Usp[] = messages.map((message, i) => ({
      message,
      icon: icons[i] ?? '',
    }))

    return mappedUsps
  }

  const getAndSetUSPfromCache = (searchTerm: CategoryId | string): Usp[] | void => {
    if (state.usps[searchTerm]) return state.usps[searchTerm]
    const cacheUsps = getCache(`pcpUsps_${locale.value}_${searchTerm}`)
    if (cacheUsps) {
      state.usps[searchTerm] = cacheUsps
      return cacheUsps
    }
  }

  const getUSP = async (searchTerm: CategoryId): Promise<Usp[] | void> => {
    if (getAndSetUSPfromCache(searchTerm)) return
    const pageData = await fetchBloomreachPage(`/pcp?q=${searchTerm}&u=${searchTerm}`)
    if (!pageData?.page) return

    const data =
      Object.values(pageData.page).find((object) => object?.data?.contentType === 'brxsaas:mjPLPUSP')?.data || {}

    const usp = mapDataToUsps(data)
    setCache(`pcpUsps_${locale.value}_${searchTerm}`, usp)
    return usp
  }

  const getGlobalUsps = async () => {
    try {
      if (getAndSetUSPfromCache('global')) return

      const data = (await $fetch(
        $brxmEndpointResolver.resolveDocumentsEndPoint(`/pcpusp/global`),
      )) as BrDocumentResponse

      const usp = mapDataToUsps(data?.content[data?.document?.$ref.substring(9)].data)
      setCache(`pcpUsps_${locale.value}_global`, usp)
      state.usps['global'] = usp
    } catch (error) {
      logger.warn('Failed to global usps', error)
    }
  }

  const loadPcpUsps = async () => {
    const categoryIds = breadcrumbs.value
      ?.filter((breadcrumb) => breadcrumb.id)
      .map((breadcrumb) => breadcrumb.id)
      .reverse()
    if (state.loading) return

    state.loading = true

    await getGlobalUsps()

    await Promise.all(
      categoryIds.map(async (categoryId) => {
        if (!state.usps?.[categoryId]) {
          const categoryUsps = await getUSP(categoryId)
          if (!categoryUsps) return
          state.usps = {
            ...state.usps,
            [categoryId]: categoryUsps,
          }
        }
      }),
    )

    // Get first available USPs, fallback on global
    categoryIds.push('global')
    state.currentUsps = categoryIds.reduce((acc, categoryId) => {
      if (!acc.length && state.usps[categoryId]) {
        acc = state.usps[categoryId]
      }
      return acc
    }, [])

    state.loading = false
  }

  return {
    ...toRefs(state),
    loadPcpUsps,
  }
})

export default usePCPStore
