import type {
  AddConfigurableProductsToCartInput,
  AddSimpleProductsToCartInput,
  Cart,
  CartItemInput,
} from '@vue-storefront/magento-types'
import { cartCookieName } from '~/enums/cookieNameEnum'

/** Params object used to add items to a cart */
interface CartItemInputWithCustomOptions extends CartItemInput {
  entered_options?: any[]
}

export declare type AddProductsToCartInput = {
  cartId: string
  cartItems: CartItemInputWithCustomOptions[]
}

const addSimpleProduct = async ({ cartId, quantity, product, productCustomOptionsConfiguration }) => {
  const { $sdk } = useNuxtApp()
  const simpleCartInput: AddSimpleProductsToCartInput = {
    cart_id: cartId,
    cart_items: [
      {
        data: {
          quantity,
          sku: product.sku,
        },
      },
    ],
  }

  if (productCustomOptionsConfiguration) {
    simpleCartInput.cart_items[0].customizable_options = Object.entries(productCustomOptionsConfiguration).map(
      ([id, value_string]) => ({
        id: parseInt(id),
        value_string: value_string.toString(),
      }),
    )
  }

  let { data: simpleProduct, errors } = await $sdk.magento.addSimpleProductsToCart(simpleCartInput, {
    addSimpleProductsToCart: 'add-simple-products-to-cart',
  })

  if (errors?.length) throw errors

  const cartStore = useCartStore()
  cartStore.setCart(simpleProduct!.addSimpleProductsToCart?.cart)

  console.debug('[Result]:', { data: simpleProduct })

  return simpleProduct!.addSimpleProductsToCart?.cart as Cart
}

const addConfigurableProduct = async ({
  cartId,
  quantity,
  product,
  productConfiguration,
  productCustomOptionsConfiguration,
}) => {
  const { $sdk } = useNuxtApp()
  const selectedOptions = Object.values(productConfiguration as object)
  const customOptions = Object.entries(productCustomOptionsConfiguration).map(([id, value_string]) => ({
    id: parseInt(id),
    value_string: value_string.toString(),
  }))
  const selectedVariant = product.variants.find((variant) =>
    selectedOptions.every((option) => variant.attributes.find((attribute) => attribute.uid === option)),
  )

  if (!selectedVariant) return console.warn('No variant found for product ' + product.sku)

  const configurableCartInput: AddConfigurableProductsToCartInput = {
    cart_id: cartId,
    cart_items: [
      {
        data: {
          quantity,
          sku: selectedVariant.product.sku,
          selected_options: selectedOptions,
        },
        parent_sku: product.sku,
        customizable_options: customOptions,
      },
    ],
  }

  let { data: configurableProduct, errors } = await $sdk.magento.addConfigurableProductsToCart(configurableCartInput, {
    addConfigurableProductsToCart: 'add-configurable-products-to-cart',
  })

  if (errors?.length) throw errors

  const cartStore = useCartStore()
  cartStore.setCart(configurableProduct!.addConfigurableProductsToCart?.cart)

  console.debug('[Result]:', { data: configurableProduct })

  return configurableProduct!.addConfigurableProductsToCart?.cart as Cart
}

const addBundleProduct = async ({ cartId, quantity, product }) => {
  const { $sdk } = useNuxtApp()
  const createEnteredOptions = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    product.bundle_options.map((bundleOption) => ({
      ...bundleOption,
      value: bundleOption.value.toString(),
    }))

  const bundleCartInput: AddProductsToCartInput = {
    cartId,
    cartItems: [
      {
        quantity,
        sku: product.sku,
        entered_options: createEnteredOptions(),
      },
    ],
  }

  let { data: bundleProduct, errors } = await $sdk.magento.addProductsToCart(bundleCartInput, {
    addProductsToCart: 'add-products-to-cart',
  })

  if (errors?.length) throw errors

  const cartStore = useCartStore()

  cartStore.setCart(bundleProduct!.addProductsToCart?.cart)

  console.debug('[Result]:', { data: bundleProduct })

  return bundleProduct!.addProductsToCart?.cart as Cart
}

const addDownloadableProduct = async ({ cartId, quantity, product }) => {
  const { $sdk } = useNuxtApp()
  const downloadableCartInput: AddProductsToCartInput = {
    cartId,
    cartItems: [
      {
        quantity,
        sku: product.sku,
      },
    ],
  }

  let { data: downloadableProduct, errors } = await $sdk.magento.addProductsToCart(downloadableCartInput, {
    addProductsToCart: 'add-products-to-cart',
  })

  if (errors?.length) throw errors

  //@TODO - remove this when we can use actual custom queries
  const cartStore = useCartStore()
  cartStore.setCart(downloadableProduct!.addProductsToCart?.cart)

  console.debug('[Result DownloadableProduct]:', { data: downloadableProduct })

  return downloadableProduct!.addProductsToCart?.cart as Cart
}

const addVirtualProduct = async ({ cartId, quantity, product }) => {
  const { $sdk } = useNuxtApp()
  const virtualCartInput: AddProductsToCartInput = {
    cartId,
    cartItems: [
      {
        quantity,
        sku: product.sku,
      },
    ],
  }
  let { data: virtualProduct, errors } = await $sdk.magento.addProductsToCart(virtualCartInput, {
    addProductsToCart: 'add-products-to-cart',
  })

  if (errors?.length) throw errors

  const cartStore = useCartStore()
  cartStore.setCart(virtualProduct!.addProductsToCart?.cart)

  console.debug('[Result VirtualProduct]:', { data: virtualProduct })

  return virtualProduct!.addProductsToCart?.cart as Cart
}

export const addItemCommand = {
  execute: async ({ product, quantity, currentCart, productConfiguration, productCustomOptionsConfiguration }) => {
    console.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart,
    })
    const cartCookie = useCookie(cartCookieName)

    const cartId = cartCookie.value?.indexOf('|') ? cartCookie.value?.split('|').pop() : cartCookie.value

    if (!product) {
      return
    }

    switch (product.__typename) {
      case 'SimpleProduct':
        return addSimpleProduct({ cartId, quantity, product, productCustomOptionsConfiguration })
      case 'ConfigurableProduct':
        return addConfigurableProduct({
          cartId,
          quantity,
          product,
          productConfiguration,
          productCustomOptionsConfiguration,
        })
      case 'BundleProduct':
        return addBundleProduct({ cartId, quantity, product })
      case 'DownloadableProduct':
        return addDownloadableProduct({ cartId, quantity, product })
      case 'VirtualProduct':
        return addVirtualProduct({ cartId, quantity, product })
      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`)
    }
  },
}
