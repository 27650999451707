type Query = {
  path: string
  catalogId?: string
  catalogType?: string
  catalogPath?: string
}

export const { mapping } = useBloomreachComponents()

export const setPageData = async () => {
  const logger = useAppLogger('setPageData')
  const pageStore = usePageStore()
  const route = useRoute()

  const path = route.fullPath.replace(/\/$/, '') // Remove trailing slash
  const query: Query = {
    path,
  }

  let catalogData = null
  if (route.fullPath?.split('?')?.shift()?.endsWith('.html')) {
    const data = route.meta.data
    query.catalogId = data.id
    query.catalogType = data.type
    query.catalogPath = data.path
    catalogData = data
  }

  let searchRouteData = null

  const cacheKey = path
  const { data: page } = useNuxtData(cacheKey)

  if (route.path.includes('/catalogsearch')) {
    searchRouteData = {
      ...page.value?.catalog,
      type: 'SEARCH',
      relative_url: 'catalogsearch/result',
      query: JSON.parse(JSON.stringify(route.query)),
    }
  }
  if (!page.value) {
    logger.info('fetching data', cacheKey)

    page.value = await $fetch('/api/bloomreach/page', { query }).catch((e) => {
      throw showError({
        statusCode: e.statusCode,
        statusMessage: e.statusMessage,
        fatal: true,
      })
    })
  } else {
    logger.info('using cached data', cacheKey)
  }

  pageStore.pageType = route.path.includes('/catalogsearch') ? 'SEARCH' : page.value?.pageType || 'BLOOMREACH'
  pageStore.pageData = page.value?.pageData
  pageStore.configuration = page.value?.configuration
  pageStore.routeData = searchRouteData || catalogData || {}
  pageStore.hrefLangs = page.value?.hrefLangs || []
  pageStore.bloomreachPage = page.value?.bloomreachPage || []

  return {
    type: route.path.includes('/catalogsearch') ? 'SEARCH' : page.value?.pageType || 'BLOOMREACH',
    page: page.value.bloomreachPage,
    path: catalogData?.path || '',
  }
}

export default setPageData
