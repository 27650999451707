import type { Cart, UpdateCartItemsInput } from '@vue-storefront/magento-types'

export const updateItemQtyCommand = {
  execute: async ({ currentCart, product, quantity }) => {
    const { $sdk } = useNuxtApp()
    console.debug('[Magento]: Update product quantity on cart', {
      product,
      quantity,
      currentCart,
    })

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_uid: product.uid,
          quantity,
        },
      ],
    }

    const { data, errors } = await $sdk.magento.updateCartItems(updateCartParams, {
      updateCartItems: 'update-cart-items',
    })

    console.debug('[Result]:', { data, errors })

    if (!data?.updateCartItems && errors?.length) {
      throw errors[0]
    }

    return data.updateCartItems.cart as unknown as Cart
  },
}
