export default (func: any, wait: number = 500) => {
  let debouncer: any = null

  const debounce = (...params: any[]) => {
    clearTimeout(debouncer)
    debouncer = setTimeout(() => func(...params), wait)
  }

  debounce.cancel = () => clearTimeout(debouncer)

  return debounce
}
