// https://vee-validate.logaretm.com/v4/guide/global-validators
import { defineRule } from 'vee-validate'

export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp()

  defineRule('required', (value: any): boolean | string => {
    const fieldValue = value?.toString().trim()
    if (!fieldValue || !fieldValue.length) {
      return $i18n.t('This field is required')
    }
    return true
  })

  defineRule('minLength', (value: any, [limit]: [number]) => {
    if (!value || !value.length) {
      return true
    }
    if (value.length < limit) {
      return $i18n.t(`The field should have at least {amount} characters`, { amount: limit })
    }
    return true
  })

  defineRule('email', (value: any): boolean | string => {
    if (!value || !value.length) {
      return true
    }

    const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegExp.test(value)) {
      return $i18n.t('Invalid email')
    }
    return true
  })

  defineRule('phone', (value: any): boolean | string => {
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    if (!value || !value.length) {
      return true
    }

    if (!phoneRegExp.test(value)) {
      return $i18n.t('Invalid phone number')
    }
    return true
  })
})
